import VideoIsoRotate from "./assets/video/iso_rotate.mp4";
import "./App.css";

function App() {
  return (
    <div className="video-container">
      <video controls={false} autoPlay loop>
        <source src={VideoIsoRotate} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="on-filter-video" />
    </div>
  );
}

export default App;
